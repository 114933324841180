import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Lalpaharirdesh - Jhargram, IN</title>
                <desc>Discover offbeat destinations in Bengal with Lalpaharirdesh. Explore Jhargram, Ghatshila, Bankura, and Purulia for nature, culture, and unforgettable experiences. Plan your perfect getaway today!</desc>
            </Helmet>
            <>
                <CustomHeader>

                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white animated slideInDown">Privacy Policy</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Privacy Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </CustomHeader>
                <div class="container">
                    <div className=" text-center py-5">
                        <h6 className="section-title bg-white text-center text-primary px-3" >Privacy Policy</h6>
                        <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Privacy & Policy</h1>
                    </div>

                    <p><strong>Effective Date:</strong> 27 Nov,2024</p>
                    <p>At <strong>Lalpaharirdesh</strong>, we value your trust and are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website (<a href="https://www.lalpaharirdesh.in" target="_blank">www.lalpaharirdesh.in</a>). By using our Website, you consent to the practices described in this policy.</p>

                    <h2>1. Information We Collect</h2>
                    <ul>
                        <li><strong>Personal Information:</strong> Name, email address, phone number, billing address, and payment details when you make bookings or inquiries.</li>
                        <li><strong>Non-Personal Information:</strong> Browser type, IP address, device information, and browsing habits for analytical purposes.</li>
                        <li><strong>Cookies:</strong> Small data files stored on your device to enhance user experience and improve our services.</li>
                    </ul>

                    <h2>2. How We Use Your Information</h2>
                    <ul>
                        <li>Processing bookings and managing tour-related queries.</li>
                        <li>Personalizing your experience and providing tailored recommendations.</li>
                        <li>Sending updates, promotional offers, and notifications (with your consent).</li>
                        <li>Improving our Website through analytics and user feedback.</li>
                        <li>Ensuring security and preventing fraudulent activities.</li>
                    </ul>

                    <h2>3. Sharing Your Information</h2>
                    <p>We do not sell, trade, or rent your personal information. However, we may share information:</p>
                    <ul>
                        <li>With trusted service providers (e.g., payment gateways or booking platforms) necessary to process transactions.</li>
                        <li>To comply with legal obligations or enforce our Terms of Service.</li>
                        <li>For marketing purposes (only non-identifiable data) to understand user behavior.</li>
                    </ul>

                    <h2>4. Cookies Policy</h2>
                    <p>We use cookies to:</p>
                    <ul>
                        <li>Remember your preferences and enhance your experience.</li>
                        <li>Analyze traffic patterns and improve website functionality.</li>
                    </ul>
                    <p>You can disable cookies through your browser settings; however, this may limit certain features on our Website.</p>

                    <h2>5. Data Security</h2>
                    <p>We implement reasonable security measures to protect your personal information. However, no data transmission or storage can be guaranteed as 100% secure. Please take precautions while sharing sensitive information online.</p>

                    <h2>6. Your Rights</h2>
                    <ul>
                        <li>Access, update, or delete your personal information by contacting us.</li>
                        <li>Opt-out of receiving promotional communications.</li>
                        <li>Request clarification on how we process your data.</li>
                    </ul>

                    <h2>7. Third-Party Links</h2>
                    <p>Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. Please review their privacy policies before interacting with them.</p>

                    <h2>8. Changes to This Policy</h2>
                    <p>We reserve the right to update this Privacy Policy at any time. Changes will be reflected on this page with a revised effective date.</p>

                    <h2>9. Contact Us</h2>
                    <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at:</p>
                    <ul>
                        <li><strong>Email:</strong> support@lalpaharirdesh.in</li>
                        <li><strong>Phone:</strong> 7001631885</li>
                        <li><strong>Address:</strong> Jhargram, West Bengal,721507</li>
                    </ul>
                </div>
            </>
        </>
    )
}

export default PrivacyPolicy