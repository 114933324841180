import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'

function TermsCondition() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <>
            <Helmet>
                <title>Terms & Conditions | Lalpaharirdesh - Jhargram, IN</title>
                <desc>Discover offbeat destinations in Bengal with Lalpaharirdesh. Explore Jhargram, Ghatshila, Bankura, and Purulia for nature, culture, and unforgettable experiences. Plan your perfect getaway today!</desc>
            </Helmet>
            <>
                <CustomHeader>

                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white animated slideInDown">Terms & Conditions</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Terms & Conditions</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </CustomHeader>
                <div class="container">
                    <div className=" text-center py-5">
                        <h6 className="section-title bg-white text-center text-primary px-3" >Terms & Conditions</h6>
                        <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Terms & Conditions</h1>
                    </div>

                    <h1>Terms & Conditions</h1>
                    <p><strong>Effective Date:</strong> 27 Nov,2024</p>
                    <p>Welcome to <strong>Lalpaharirdesh</strong>. By using our website (<a href="https://www.lalpaharirdesh.com" target="_blank">www.lalpaharirdesh.com</a>) and services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before using our platform.</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing or using our Website, you confirm that you accept these Terms & Conditions and agree to be bound by them. If you do not agree with any part of these terms, you must not use our Website.</p>

                    <h2>2. Booking and Payment</h2>
                    <ul>
                        <li>All bookings are subject to availability and confirmation from our team.</li>
                        <li>Full payment or a deposit is required at the time of booking to secure your reservation.</li>
                        <li>Cancellation and refund policies vary by package and will be detailed at the time of booking.</li>
                    </ul>

                    <h2>3. Use of Website</h2>
                    <ul>
                        <li>You agree to use the Website for lawful purposes only.</li>
                        <li>Unauthorized use of this Website may give rise to a claim for damages and/or be a criminal offense.</li>
                        <li>We reserve the right to terminate your access if you violate these terms.</li>
                    </ul>

                    <h2>4. Intellectual Property</h2>
                    <p>All content on this Website, including text, graphics, logos, and images, is the property of Lalpaharirdesh or its content suppliers and is protected by copyright laws. You may not reproduce, distribute, or use any material without prior written consent.</p>

                    <h2>5. Limitation of Liability</h2>
                    <p>We strive to ensure the accuracy of information on our Website. However, we do not guarantee the completeness or reliability of the content. Lalpaharirdesh is not liable for any direct, indirect, or consequential loss arising from your use of our services or Website.</p>

                    <h2>6. Changes to Terms</h2>
                    <p>We may update these Terms & Conditions at any time without prior notice. Changes will be effective immediately upon posting on the Website. We encourage you to review this page periodically to stay informed of any updates.</p>

                    <h2>7. Governing Law</h2>
                    <p>These Terms & Conditions are governed by the laws of [Insert Jurisdiction]. Any disputes arising from your use of the Website will be subject to the exclusive jurisdiction of the courts of Jhargram.</p>

                    <h2>8. Contact Us</h2>
                    <p>If you have any questions or concerns regarding these Terms & Conditions, please contact us at:</p>
                    <ul>
                        <li><strong>Email:</strong> support@lalpaharirdesh.in</li>
                        <li><strong>Phone:</strong> 7001631885</li>
                        <li><strong>Address:</strong> Jhargram, West Bengal,721507</li>
                    </ul>
                </div>
            </>
        </>
    )
}

export default TermsCondition