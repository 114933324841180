import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'

function Contact() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    return (
        <>
            <Helmet>
                <title>Contact Us | Lalpaharirdesh - Jhargram, IN</title>
                <desc>Discover offbeat destinations in Bengal with Lalpaharirdesh. Explore Jhargram, Ghatshila, Bankura, and Purulia for nature, culture, and unforgettable experiences. Plan your perfect getaway today!</desc>
            </Helmet>
            <>
                <CustomHeader>
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white animated slideInDown" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Contact Us</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </CustomHeader>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="section-title bg-white text-center text-primary px-3" >Contact Us</h6>
                            <h1 className="mb-5" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Contact For Any Query</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <h3 style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Have questions or need assistance? </h3>
                                <p className="mb-4">Get in touch with us for prompt support and personalized travel guidance!</p>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{ "width": "50px", height: "50px" }}>
                                        <i className="fa fa-map-marker-alt text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h5 className="text-primary" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Office</h5>
                                        <p className="mb-0">Jhargram, West bengal ,721507</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{ "width": "50px", height: "50px" }}>
                                        <i className="fa fa-phone-alt text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h5 className="text-primary" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Mobile</h5>
                                        <p className="mb-0">+91 9064800743</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{ "width": "50px", height: "50px" }}>
                                        <i className="fa fa-envelope-open text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h5 className="text-primary" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Email</h5>
                                        <p className="mb-0">lalpaharirdeshofficial@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <iframe className="position-relative rounded w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36797.287797482946!2d86.97988552904665!3d22.451188742994194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d7bcae512b101%3A0xd6cf20a23cf93fab!2sJhargram%2C%20West%20Bengal!5e1!3m2!1sen!2sin!4v1729715112943!5m2!1sen!2sin"
                                    frameborder="0" style={{ minHeight: "300px", border: "0px" }} allowfullscreen="" aria-hidden="false"
                                    tabindex="0"></iframe>
                            </div>
                            <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ "height": "100px" }}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default Contact