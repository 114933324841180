import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import DestinationComp from '../../components/HomeComponents/DestinationComp'
import CustomHeader from '../../components/CustomHeader'

function Destination() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <>
            <Helmet>
                <title>Destination | Lalpaharirdesh - Jhargram, IN</title>
                <desc>Discover offbeat destinations in Bengal with Lalpaharirdesh. Explore Jhargram, Ghatshila, Bankura, and Purulia for nature, culture, and unforgettable experiences. Plan your perfect getaway today!</desc>
            </Helmet>
            <>
                <CustomHeader>
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white animated slideInDown" style={{ fontFamily: 'initial', fontWeight: 'bolder' }}>Destination</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Destination</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </CustomHeader>
                <DestinationComp />
            </>
        </>
    )
}

export default Destination