import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'
import FaqComp from '../../components/HomeComponents/FaqComp'
import { BlogDetails } from '../../actions/authAction'
import moment from 'moment'

function BlogSingle() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const blogId = queryParams.get("blogId");
    const [blogSingle, setSingleBlog] = useState(null)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (blogId) {
            getBloghandler()
        }
    }, [blogId])

    const getBloghandler = async () => {
        let result = await BlogDetails(blogId)
        console.log('result', result)
        if (result && result.status) {
            setSingleBlog(result?.data)
        }
    }
    return (
        <>
            <Helmet>
                <title>{`${blogSingle?.title}`}| Lalpaharirdesh - Jhargram, IN</title>
                <desc>Discover offbeat destinations in Bengal with Lalpaharirdesh. Explore Jhargram, Ghatshila, Bankura, and Purulia for nature, culture, and unforgettable experiences. Plan your perfect getaway today!</desc>
            </Helmet>
            <>
                <CustomHeader>
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white animated slideInDown">{blogSingle?.title}</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Package Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </CustomHeader>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card mb-4">
                                <a href="#!"><img className="card-img-top" src={blogSingle?.image} alt="..." /></a>
                                <div className="card-body">
                                    <div className="small text-muted">{moment(blogSingle?.createdOn).format('ll')}</div>
                                    {/* <h2 className="card-title"></h2> */}
                                    <div className="card-text" dangerouslySetInnerHTML={{ __html: blogSingle?.description }} />
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-6">

                                    <div className="card mb-4">
                                        <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                        <div className="card-body">
                                            <div className="small text-muted">January 1, 2023</div>
                                            <h2 className="card-title h4">Post Title</h2>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis aliquid atque, nulla.</p>
                                            <a className="btn btn-primary" href="#!">Read more →</a>
                                        </div>
                                    </div>

                                    <div className="card mb-4">
                                        <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                        <div className="card-body">
                                            <div className="small text-muted">January 1, 2023</div>
                                            <h2 className="card-title h4">Post Title</h2>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis aliquid atque, nulla.</p>
                                            <a className="btn btn-primary" href="#!">Read more →</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">

                                    <div className="card mb-4">
                                        <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                        <div className="card-body">
                                            <div className="small text-muted">January 1, 2023</div>
                                            <h2 className="card-title h4">Post Title</h2>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis aliquid atque, nulla.</p>
                                            <a className="btn btn-primary" href="#!">Read more →</a>
                                        </div>
                                    </div>

                                    <div className="card mb-4">
                                        <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                        <div className="card-body">
                                            <div className="small text-muted">January 1, 2023</div>
                                            <h2 className="card-title h4">Post Title</h2>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis aliquid atque, nulla? Quos cum ex quis soluta, a laboriosam.</p>
                                            <a className="btn btn-primary" href="#!">Read more →</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-header">Search</div>
                                <div className="card-body">
                                    <div className="input-group">
                                        <input className="form-control" type="text" placeholder="Enter search term..." aria-label="Enter search term..." aria-describedby="button-search" />
                                        <button className="btn btn-primary" id="button-search" type="button">Go!</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header">Categories</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <ul className="list-unstyled mb-0">
                                                {
                                                    blogSingle?.blogCategory?.length > 0 &&
                                                    blogSingle?.blogCategory?.map((it) => {
                                                        return (
                                                            <li><a href="#!">{it?.name}</a></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header">Tags</div>
                                <div className="card-body">No Tags !!</div>
                            </div>
                        </div>
                    </div>
                </div>

                <FaqComp />
            </>
        </>
    )
}

export default BlogSingle;