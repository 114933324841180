import React, { Suspense, lazy } from 'react';
import { Routes, Navigate, Route, useLocation } from 'react-router-dom';

import SiteLayout from './layouts/SiteLayout';
import MainLayout from './layouts/MainLayout';

import GuestGuard from './guards/GuestGuard';
import UserGuard from './guards/UserGuard';

import LoadingScreen from './components/LoadingScreen';
import Dashboard from './views/user/Dashboard/Dashboard';
import LandingPage from './views/auth/LandingPage';
import Page404 from './views/errors/Page404';
import About from './views/About';
import Service from './views/Service';
import Package from './views/Packages';
import Contact from './views/Contact';
import Destination from './views/Destination';
import Booking from './views/Booking';
import TravellGuide from './views/TravellGuide';
import Feedback from './views/Feedback';
import BlogSingle from './views/Blog/BlogSingle';
import Faq from './views/Faq';
import PackageDetails from './views/Packages/PackageDetails';
import PrivacyPolicy from './views/Privacy';
import TermsCondition from './views/TermsCondition';


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>

    <Routes>
      {routes.map((route, i) => {
        const location = useLocation();
        // const Guard = route.guard || Fragment;
        // const Layout = route.layout || Fragment;
        // const Component = route.component;
        let UNAUTH_ROUTE = ["/", "/about", "/service", "/package", "/contact", '/destination', "/booking", "/team", "/testimonial", "/blog", "/faq", "/details", "/privacy", "/terms"]
        let AUTH_ROUTE = ["/user/dashboard", "/user"]
        console.log(location, route.path)
        return (
          // location.pathname === "/" 
          // || location.pathname === "/login" 
          // || location.pathname === "/register" 
          // || location.pathname === "/forget-pass" 
          // || location.pathname === "/otp-verification" 
          UNAUTH_ROUTE.includes(location.pathname)
            ?
            <Route
              key={i}
              path={location.pathname}
              // exact={route.exact}
              exact={true}
              element={
                <GuestGuard>
                  <MainLayout>
                    {(location.pathname === "/") ? <LandingPage />
                      : (location.pathname === "/about") ? <About />
                        : location.pathname === "/service" ? <Service />
                          : location.pathname === "/package" ? <Package />
                            : location.pathname === "/details" ? <PackageDetails />
                              : location.pathname === "/contact" ? <Contact />
                                : location.pathname === "/destination" ? <Destination />
                                  : location.pathname === "/booking" ? <Booking />
                                    : location.pathname === "/team" ? <TravellGuide />
                                      : location.pathname === "/testimonial" ? <Feedback />
                                        : location.pathname === "/faq" ? <Faq />
                                          : location.pathname === "/blog" ? <BlogSingle />
                                            : location.pathname === "/privacy" ? <PrivacyPolicy />
                                              : location.pathname === "/terms" ? <TermsCondition />
                                                : ""
                    }
                  </MainLayout>
                </GuestGuard>
              }
            />
            //   : (location.pathname.includes("/user/dashboard")
            //   || location.pathname.includes("/user")
            // )
            : AUTH_ROUTE.includes(location.pathname) ?
              <Route
                key={i}
                path={location.pathname}
                // exact={route.exact}
                exact={true}
                element={
                  <UserGuard>
                    <MainLayout>
                      {location.pathname.includes("/user/dashboard") ? <Dashboard />
                        : location.pathname.includes("/user") ? <Navigate to="/user/dashboard" />
                          : <Navigate to="/404" />
                      }
                    </MainLayout>
                  </UserGuard>
                }
              />
              :
              <Route
                key={i}
                path={location.pathname}
                // exact={route.exact}
                exact={true}
                element={
                  <>
                    <>
                      <Navigate to="/404" />
                      <Page404 />
                    </>
                  </>
                }
              />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  // anonymous routes
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/Page404'))
  },
  {
    exact: true,
    layout: SiteLayout,
    guard: GuestGuard,
    path: '/',
  },
  {
    exact: true,
    layout: SiteLayout,
    guard: GuestGuard,
    path: '/login',
    // component: lazy(() => import('./views/auth/Login'))
  },
  {
    exact: true,
    layout: SiteLayout,
    guard: GuestGuard,
    path: '/register',
    // component: lazy(() => import('./views/auth/Register'))
  },
  {
    exact: true,
    layout: SiteLayout,
    guard: GuestGuard,
    path: '/otp-verification',
    // component: lazy(() => import('./views/auth/ForgetPass/VerifyAccountPassword'))
  },
  {
    exact: true,
    layout: SiteLayout,
    guard: GuestGuard,
    path: '/forget-pass',
    // component: lazy(() => import('./views/auth/ForgetPass'))
  },
  {
    exact: true,
    path: '/user',
    guard: UserGuard,
    layout: MainLayout,
    component: () => <Navigate to="/user/dashboard" />
  },
  {
    exact: true,
    path: '/user/dashboard',
    guard: UserGuard,
    layout: MainLayout,
    // component: React.lazy(() => import('./views'))
  },

];

export default routes;
